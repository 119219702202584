import { t } from 'setup-localization';
import { notify } from 'notifications';
import {
  GroupByEnum,
  ProductionWorkflow,
  ProductionWorkflowOrderT,
  ProductionWorkflowProductT,
  ProductionWorkflowTagModel,
  ProductionWorkflowUpdateBody,
  ProductionWorkflowResponseDataT,
  AssignResponsibleToProductionsBody,
} from 'services/production-workflow.model';
import FireMinusIcon from 'icons/fire-minus';
import { UNASSIGNED } from 'constants/unassigned';
import { StateController } from 'state-controller';
import { MODALS } from 'modules/root-modals/modals';
import { UserService } from 'services/user.service';
import { WebsocketEvent } from 'types/common-enums';
import { OrdersService } from 'services/orders.service';
import { AppState, GetStateFunction } from 'redux/store';
import { User, UserShortModel } from 'services/user.model';
import { AccessLevel, Permission } from 'services/permission.model';
import {
  ProductionItemsT,
  GetAndSetProductionsByIdsArgs,
  HandleLaunchingProductionsIdsArgs,
  HandleOpenCloseProductionGroupArgs,
  SetProductionsWithOpenedNestedProductionsArgs,
  WebsocketResponseMessageForProductionWorkflowT,
} from 'pages/production/controllers/production-list-controller/types';
import { checkIfPlaneView } from 'pages/production/controllers/helpers';
import {
  removeProductions,
  findProductionById,
  mergeProductionWorkflows,
  updateProductionRecursively,
  getAllProductionsOnTheScreen,
  updateMultipleProductionsRecursively,
} from 'pages/production/controllers/production-list-controller/helpers';
import { ModalActions } from 'modules/root-modals/root-modals.controller';
import { ProductionStatusEnum, UserStatusEnum } from 'types/status-enums';
import { ProductionWorkflowService } from 'services/production-workflow.service';
import { PermissionGuardActions } from 'modules/permission-guard/permission-guard.controller';
import { ManageSelectOrDeselectAllProductionsArgs } from 'pages/production/controllers/types';
import { PAGE_SIZE } from 'components/ui-new/dropdown-user-search-selector/dropdown-user-search-selector';
import { DeleteConfirmationOwnProps } from 'modules/root-modals/modals/confirmation-modal/confirmation-modal';
import { ProductionFiltersActions } from 'pages/production/controllers/production-filters-controller/production-filters.controller';
import { ProductionsLaunchingProgressActions } from 'pages/production/production-launching-progress-modal/production-launching-progress-modal.controller';

export type ProductionState = {
  users: Array<User>;
  issuesCount: number;
  isEnableMultiActions: boolean;
  launchingProductionIds: string[];
  productionItems: ProductionItemsT;
  openedProductionGroupIds: string[];
  parentIdsOfSelectedProductions: string[];
  selectedProductions: ProductionWorkflow[];
  assigningResponsibleToProductionIds: string[];
  productionWithOpenedNestedProductions: string[];
};

const defaultState: ProductionState = {
  users: [],
  issuesCount: 0,
  selectedProductions: [],
  launchingProductionIds: [],
  isEnableMultiActions: false,
  openedProductionGroupIds: [],
  parentIdsOfSelectedProductions: [],
  assigningResponsibleToProductionIds: [],
  productionWithOpenedNestedProductions: [],
  productionItems: { groupBy: GroupByEnum.None, data: [] },
};

const stateController = new StateController<ProductionState>('PRODUCTION', defaultState);

export class ProductionListActions {
  static initPageData() {
    return async (dispatch) => {
      dispatch(ProductionListActions.loadUsers());
      await dispatch(ProductionFiltersActions.loadFilters());
      dispatch(ProductionFiltersActions.getProductionsByFilter({ isInitialLoad: true }));
    };
  }

  static silentAttachOrDetachTags(productionId: string, tag: ProductionWorkflowTagModel) {
    return async (dispatch, getState: GetStateFunction) => {
      const { filters } = getState().production.filters;

      if (filters.tags.value.find(({ id }) => tag.id === id)) {
        dispatch(ProductionFiltersActions.onFiltersChange({ tags: [tag] }));

        return;
      }

      const { productionItems } = getState().production.productionList;

      const updateTags = (workflow: ProductionWorkflow) => {
        const tagExists = workflow.tags.some(({ id }) => id === tag.id);
        return {
          ...workflow,
          tags: tagExists ? workflow.tags.filter(({ id }) => id !== tag.id) : [tag, ...workflow.tags],
        };
      };

      const updateWorkflow = (workflow: ProductionWorkflow) => {
        const updatedWorkflow = {
          ...workflow,
          nested_workflows: workflow.nested_workflows?.map(updateWorkflow),
          additionalComponents: workflow.additionalComponents?.map(updateWorkflow),
        };

        if (workflow.id === productionId) {
          return updateTags(updatedWorkflow);
        }

        return updatedWorkflow;
      };

      const updateProduction = (production: ProductionWorkflow) => {
        if (productionItems.groupBy === GroupByEnum.None) {
          return updateWorkflow(production);
        }

        return {
          ...production,
          production_workflows: (production as unknown as ProductionWorkflowOrderT).production_workflows.map(updateWorkflow),
        };
      };

      const updatedProductionItems = productionItems.data.map((production) => updateProduction(production as ProductionWorkflow));

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          productionItems: {
            ...productionItems,
            data: updatedProductionItems,
          },
        })),
      );
    };
  }

  static silentDeleteTags(tag: ProductionWorkflowTagModel) {
    return async (dispatch, getState: GetStateFunction) => {
      const { filters } = getState().production.filters;

      if (filters.tags.value.find(({ id }) => tag.id === id)) {
        dispatch(ProductionFiltersActions.onFiltersChange({ tags: null }));

        return;
      }

      const { productionItems } = getState().production.productionList;

      const updateTags = (tags: ProductionWorkflowTagModel[]) => {
        return tags.filter(({ id }) => tag.id !== id);
      };

      const updateWorkflowTags = (workflow: ProductionWorkflow) => {
        return {
          ...workflow,
          tags: updateTags(workflow.tags),
          nested_workflows: workflow.nested_workflows?.map(updateWorkflowTags),
          additionalComponents: workflow.additionalComponents?.map(updateWorkflowTags),
        };
      };

      const updateProduction = (production: ProductionWorkflow) => {
        if (productionItems.groupBy === GroupByEnum.None) {
          return updateWorkflowTags(production);
        }

        return {
          ...production,
          production_workflows: (production as unknown as ProductionWorkflowOrderT).production_workflows.map(updateWorkflowTags),
        };
      };

      const updatedProductionItems = productionItems.data.map((production) => updateProduction(production as ProductionWorkflow));

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          productionItems: {
            ...productionItems,
            data: updatedProductionItems,
          },
        })),
      );
    };
  }

  static silentUpdateTags(newTag: ProductionWorkflowTagModel) {
    return async (dispatch, getState: GetStateFunction) => {
      const { filters } = getState().production.filters;

      if (filters.tags.value.find(({ id }) => newTag.id === id)) {
        dispatch(ProductionFiltersActions.onFiltersChange({ tags: [newTag] }));
        dispatch(ProductionFiltersActions.getProductionsByFilter({}));

        return;
      }

      const { productionItems } = getState().production.productionList;

      const updateTags = (tags: ProductionWorkflowTagModel[]) => {
        return tags.map((tag) => (tag.id === newTag.id ? newTag : tag));
      };

      const updateWorkflowTags = (workflow: ProductionWorkflow) => {
        return {
          ...workflow,
          tags: updateTags(workflow.tags),
          nested_workflows: workflow.nested_workflows?.map(updateWorkflowTags),
          additionalComponents: workflow.additionalComponents?.map(updateWorkflowTags),
        };
      };

      const updateProduction = (production: ProductionWorkflow) => {
        if (productionItems.groupBy === GroupByEnum.None) {
          return updateWorkflowTags(production);
        }

        return {
          ...production,
          production_workflows: (production as unknown as ProductionWorkflowOrderT).production_workflows.map(updateWorkflowTags),
        };
      };

      const updatedProductionItems = productionItems.data.map((production) => updateProduction(production as ProductionWorkflow));

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          productionItems: {
            ...productionItems,
            data: updatedProductionItems,
          },
        })),
      );
    };
  }

  static handleLaunchingProductionIds = ({
    value,
    replaceWithNewValue,
    removeLaunchedProductionIds,
  }: HandleLaunchingProductionsIdsArgs) => {
    return (dispatch) => {
      if (removeLaunchedProductionIds) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            launchingProductionIds: prev.launchingProductionIds.filter((launchingId) => !value.includes(launchingId)),
          })),
        );

        return;
      }

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          launchingProductionIds: replaceWithNewValue ? value : [...prev.launchingProductionIds, ...value],
        })),
      );
    };
  };

  static setProductionItems(newData: ProductionWorkflowResponseDataT, newGroupBy?: GroupByEnum) {
    return (dispatch, getState: () => AppState) => {
      const { groupBy } = getState().production.filters;

      const { data } = getState().production.productionList.productionItems;

      const orderAndProductTypedData = data as (ProductionWorkflowOrderT | ProductionWorkflowProductT)[];

      let dataForState = newData;

      if (groupBy !== GroupByEnum.None) {
        dataForState = newData.map((item) => {
          const existingItem = orderAndProductTypedData.find((existing) => existing.id === item.id);

          if (existingItem && 'production_workflows' in existingItem) {
            return {
              ...item,
              production_workflows: existingItem.production_workflows,
            };
          }
          return {
            ...item,
            production_workflows: [],
          };
        });
      }

      dispatch(
        stateController.setState({
          productionItems: { groupBy: newGroupBy || groupBy, data: dataForState },
        }),
      );
    };
  }

  static setProductionItemsAfterLoadMore(data: ProductionWorkflowResponseDataT, newGroupBy?: GroupByEnum) {
    return (dispatch, getState: () => AppState) => {
      const { groupBy } = getState().production.filters;

      let dataForState = data;

      if (groupBy !== GroupByEnum.None) {
        dataForState = data.map((item) => ({ ...item, production_workflows: [] }));
      }

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          productionItems: {
            ...prev.productionItems,
            groupBy: newGroupBy || groupBy,
            data: [...prev.productionItems.data, ...dataForState],
          },
        })),
      );
    };
  }

  static loadUsers() {
    return async (dispatch) => {
      try {
        const users = (
          await UserService.getAllUsers({
            skip: 0,
            take: PAGE_SIZE,
            status: [UserStatusEnum.Active],
          })
        ).data;
        dispatch(stateController.setState({ users }));
      } catch (err) {
        notify.error(err.message);
        throw err;
      }
    };
  }

  static openDeleteConfirmationModal(isExternal: boolean, productionWorkflowTitle: string, action: () => void) {
    return (dispatch) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductionEdit, [AccessLevel.access]))) {
        return;
      }
      dispatch(
        ModalActions.openModal<DeleteConfirmationOwnProps>({
          id: MODALS.CONFIRM,
          props: {
            title: <>Delete production?</>,
            text: (
              <>
                <div style={{ marginBottom: '7px' }}>
                  Are you sure you want to delete <strong>{productionWorkflowTitle}</strong> production item?
                </div>
                {isExternal ? (
                  <>
                    <br />
                    <div>
                      This production item was synced from external system. We highly recommend to not delete it, but change
                      status to <strong>“Cancelled”</strong>. It will keep data integrity.
                    </div>
                  </>
                ) : null}
                <br />
                <div>You can`t undo this action.</div>
              </>
            ),
            icon: <FireMinusIcon />,
            withCloseButton: false,
            actionText: <>{t('global.button_delete')}</>,
            action: () => {
              dispatch(action);
            },
          },
        }),
      );
    };
  }

  static copyProductionWorkflowLink(id: string) {
    return async () => {
      const { origin } = window.location;
      const link = `${origin}/production-workflow/${id}`;
      await navigator.clipboard.writeText(link);
      notify.success('The link copied');
    };
  }

  static assignResponsibleToProductions(responsibleId: string, userData?: UserShortModel) {
    return async (dispatch, getState: () => AppState) => {
      const { selectedProductions } = getState().production.productionList;
      const selectedProductionIds = selectedProductions.map((production) => production.id);
      const responsibleIdForRequest = responsibleId === UNASSIGNED ? null : responsibleId;

      dispatch(stateController.setState({ assigningResponsibleToProductionIds: selectedProductionIds }));

      try {
        const assignToManyProductionsBody: AssignResponsibleToProductionsBody = {
          production_ids: selectedProductionIds,
          responsible_id: responsibleIdForRequest,
        };

        await ProductionWorkflowService.assignResponsibleToProductions(assignToManyProductionsBody);

        const { productionItems } = getState().production.productionList;

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            productionItems: {
              ...prev.productionItems,
              data: updateMultipleProductionsRecursively({
                idsToUpdate: selectedProductionIds,
                productionsTree: productionItems.data,
                value: { responsible: userData },
              }),
            },
          })),
        );
      } catch (error) {
        notify.error(error.message);
      } finally {
        dispatch(stateController.setState({ assigningResponsibleToProductionIds: [] }));
        notify.success('Successfully updated');
      }
    };
  }

  static getAndSetProductionsByIds({ groupId, productionIds, fetchedProductionWorkflowsNumber }: GetAndSetProductionsByIdsArgs) {
    return async (dispatch, getState: () => AppState) => {
      try {
        const { sort } = getState().production.filters;
        const productionIdsToGet = productionIds.slice(fetchedProductionWorkflowsNumber, fetchedProductionWorkflowsNumber + 10);
        const productions = await ProductionWorkflowService.getAllProductionsByIds({
          production_workflow_ids: productionIdsToGet,
          sort: { order: sort.sort_order.value, sortBy: sort.sort_by.value.id },
        });

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            productionItems: {
              ...prev.productionItems,
              data: prev.productionItems.data.map((item) => {
                return item.id === groupId
                  ? {
                      ...item,
                      production_workflows: fetchedProductionWorkflowsNumber
                        ? [...item.production_workflows, ...productions]
                        : [...productions],
                    }
                  : item;
              }),
            },
          })),
        );
      } catch (err) {
        notify.error(err.message);
      }
    };
  }

  static updateProduction(id: string, value: Partial<ProductionWorkflow>, isUpdateResponsible?: boolean) {
    return async (dispatch, getState: () => AppState) => {
      if (!dispatch(PermissionGuardActions.checkPermissionAndShowModal(Permission.webProductionEdit, [AccessLevel.access]))) {
        return;
      }

      const { data } = getState().production.productionList.productionItems;

      const newProductions = updateProductionRecursively({
        value,
        productions: data,
        id: value.order ? value.order.id : id,
      });

      const updateData: ProductionWorkflowUpdateBody = {
        title: value.title,
        status: value.status,
        priority: value.priority,
        ...(isUpdateResponsible ? { responsible_id: value.responsible?.id || null } : {}),
      };

      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            productionItems: {
              ...prev.productionItems,
              data: newProductions,
            },
          })),
        );

        if (value.order) await OrdersService.updateOrder(id, { priority: value.order.priority });
        if (!value.order) await ProductionWorkflowService.update(id, updateData);

        dispatch(ProductionFiltersActions.getProductionsByFilter({ showFetchEffect: false, resetSkipPreserveTake: true }));
      } catch (error) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            productionItems: {
              ...prev.productionItems,
              data,
            },
          })),
        );
        notify.error(error.message);
      }
    };
  }

  static removeProductionsFromState = (idsToRemove: string[], groupIdsToRemoveFrom: string[]) => {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          productionItems: {
            ...prev.productionItems,
            data: removeProductions({
              groupIds: groupIdsToRemoveFrom,
              productionIdsToRemove: idsToRemove,
              productionItems: prev.productionItems,
            }),
          },
        })),
      );
    };
  };

  static deleteProductionWorkflow(id: string, groupIdToRemoveFrom?: string) {
    return async (dispatch) => {
      try {
        await ProductionWorkflowService.delete(id);

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            selectedProductions: prev.selectedProductions.filter((production) => production.id !== id),
            productionItems: {
              ...prev.productionItems,
              data: removeProductions({
                productionIdsToRemove: [id],
                productionItems: prev.productionItems,
                groupIds: groupIdToRemoveFrom ? [groupIdToRemoveFrom] : [],
              }),
            },
          })),
        );

        notify.success('Deleted successfully');
      } catch (err) {
        notify.error(err.message);
        throw err;
      }
    };
  }

  static toggleIsEnableMultiActions = (value: boolean) => {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          isEnableMultiActions: value,
          selectedProductions: !value ? [] : prev.selectedProductions,
          parentIdsOfSelectedProductions: !value ? [] : prev.parentIdsOfSelectedProductions,
        })),
      );
    };
  };

  static manageSelectOrDeselectAllProductions = ({ resetAll }: ManageSelectOrDeselectAllProductionsArgs) => {
    return (dispatch, getState: () => AppState) => {
      const { selectedProductions, productionItems, openedProductionGroupIds } = getState().production.productionList;

      if (resetAll || selectedProductions.length) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            selectedProductions: [],
            parentIdsOfSelectedProductions: [],
          })),
        );
        return;
      }

      let workflows: ProductionWorkflow[] = [];

      if (checkIfPlaneView(productionItems.data)) {
        workflows = productionItems.data;
      } else {
        workflows = productionItems.data
          .filter((item) => openedProductionGroupIds.some((openedProductionId) => openedProductionId === item.id))
          .flatMap((item) => item.production_workflows);
      }

      const productions = getAllProductionsOnTheScreen(workflows);
      const parentIds = workflows.map((workflow) => workflow.id);

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          selectedProductions: productions,
          parentIdsOfSelectedProductions: parentIds,
          productionWithOpenedNestedProductions: productions
            .filter((workflow) => workflow.nested_workflows.length)
            .map((workflow) => workflow.id),
        })),
      );
    };
  };

  static updateSelectedProductionInSelectedList = (production: ProductionWorkflow) => {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          selectedProductions: prev.selectedProductions.map((item) => (item.id === production.id ? production : item)),
        })),
      );
    };
  };

  static closeAllProductionsAndProductionGroups = () => {
    return (dispatch) => {
      dispatch(stateController.setState({ openedProductionGroupIds: [], productionWithOpenedNestedProductions: [] }));
    };
  };

  static handleOpenCloseProductionGroup = ({ closeAllGroups, productionGroupId }: HandleOpenCloseProductionGroupArgs) => {
    return (dispatch, getState: () => AppState) => {
      const { openedProductionGroupIds } = getState().production.productionList;

      if (closeAllGroups) {
        dispatch(stateController.setState({ openedProductionGroupIds: [] }));

        return;
      }

      const isSelected = openedProductionGroupIds.some((item) => item === productionGroupId);

      if (isSelected) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            openedProductionGroupIds: prev.openedProductionGroupIds.filter((item) => item !== productionGroupId),
          })),
        );

        return;
      }

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          openedProductionGroupIds: [...prev.openedProductionGroupIds, productionGroupId],
        })),
      );
    };
  };

  static handleSelectDeselectProductions = (production: ProductionWorkflow | ProductionWorkflow[], parentItemId: string = '') => {
    return (dispatch, getState: () => AppState) => {
      if (Array.isArray(production)) {
        dispatch(
          stateController.setState((prev) => {
            const newSelectedProductions = production.reduce((acc, item) => {
              if (acc.some((selectedProduction) => selectedProduction.id === item.id)) {
                return acc.filter((selectedProduction) => selectedProduction.id !== item.id);
              }
              return [...acc, item];
            }, prev.selectedProductions);

            return {
              ...prev,
              isEnableMultiActions: true,
              selectedProductions: newSelectedProductions,
            };
          }),
        );

        return;
      }

      const parentIds = getState().production.productionList.parentIdsOfSelectedProductions;
      const { selectedProductions } = getState().production.productionList;

      const isSelected = selectedProductions.some((item) => item.id === production.id);

      let updatedSelectedProductions: ProductionWorkflow[] = [];
      let updatedParentIds = [...parentIds];

      if (isSelected) {
        const index = parentIds.indexOf(parentItemId);
        // This logic prevents removing parent id when child is deselected //! Don't use filter
        if (index !== -1) updatedParentIds.splice(index, 1);
        updatedSelectedProductions = selectedProductions.filter((item) => item.id !== production.id);
      } else {
        updatedSelectedProductions = [...selectedProductions, production];
        updatedParentIds = [...parentIds, parentItemId];
      }

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          isEnableMultiActions: true,
          selectedProductions: updatedSelectedProductions,
          parentIdsOfSelectedProductions: updatedParentIds,
        })),
      );
    };
  };

  static setProductionsWithOpenedNestedProductions = ({
    productionId,
    closeAllProductions,
  }: SetProductionsWithOpenedNestedProductionsArgs) => {
    return (dispatch, getState: () => AppState) => {
      if (closeAllProductions) {
        dispatch(stateController.setState({ productionWithOpenedNestedProductions: [] }));

        return;
      }

      const { productionWithOpenedNestedProductions } = getState().production.productionList;

      const isSelected = productionWithOpenedNestedProductions.some((openedProductionId) => openedProductionId === productionId);

      if (isSelected) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            productionWithOpenedNestedProductions: prev.productionWithOpenedNestedProductions.filter(
              (openedProductionId) => openedProductionId !== productionId,
            ),
          })),
        );
        return;
      }

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          productionWithOpenedNestedProductions: [...prev.productionWithOpenedNestedProductions, productionId],
        })),
      );
    };
  };

  private static replaceProduction(production: ProductionWorkflow) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => {
          return {
            ...prev,
            productionItems: {
              ...prev.productionItems,
              data: updateProductionRecursively({
                value: production,
                id: production.id,
                isReplaceComponent: true,
                productions: prev.productionItems.data,
              }),
            },
          };
        }),
      );
    };
  }

  static handleWebsocketResponse = (message: WebsocketResponseMessageForProductionWorkflowT) => {
    return (dispatch, getState: () => AppState) => {
      if (message.event === WebsocketEvent.LaunchFailed) {
        const { id, title, production_key } = message.production;

        notify.error(
          <>
            Production{' '}
            <b>
              {title} ({production_key})
            </b>{' '}
            wasn’t launched
          </>,
        );

        dispatch(
          ProductionListActions.handleLaunchingProductionIds({
            value: [id],
            removeLaunchedProductionIds: true,
          }),
        );

        return;
      }

      const productions = getState().production.productionList.productionItems.data;
      const prevProductionState = findProductionById(message.production.id, productions);
      const { launchingProductionsCount, production } = message;
      const updatedProduction = mergeProductionWorkflows(prevProductionState, production, ['responsible']);

      dispatch(ProductionListActions.replaceProduction(updatedProduction));
      dispatch(ProductionListActions.handleLaunchingProductionIds({ value: [production.id], removeLaunchedProductionIds: true }));
      dispatch(ProductionListActions.updateSelectedProductionInSelectedList(updatedProduction));

      if (!launchingProductionsCount) {
        notify.success('Successfully launched');
        dispatch(ProductionsLaunchingProgressActions.hideModal());

        return;
      }

      dispatch(ProductionsLaunchingProgressActions.setProductionsLaunchingCount(launchingProductionsCount));
    };
  };

  public static openNestedGrops(groupIds: string[] = [], productionIds: string[] = []) {
    return async (dispatch, getState: () => AppState) => {
      const { openedProductionGroupIds, productionWithOpenedNestedProductions } = getState().production.productionList;

      const groupIdsUnique = groupIds.concat(openedProductionGroupIds.filter((i) => !groupIds.includes(i)));
      const productionIdsUnique = productionIds.concat(
        productionWithOpenedNestedProductions.filter((i) => !productionIds.includes(i)),
      );

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          openedProductionGroupIds: groupIdsUnique,
          productionWithOpenedNestedProductions: productionIdsUnique,
        })),
      );
    };
  }

  public static getTargetGroupData(groupId: string) {
    return async (dispatch, getState: () => AppState) => {
      const items = getState().production.productionList.productionItems.data;
      const { productionWithOpenedNestedProductions } = getState().production.productionList;
      const targetGroup = items.find((i) => i.id === groupId) as ProductionWorkflowOrderT | ProductionWorkflowProductT;

      dispatch(ProductionListActions.openNestedGrops([targetGroup.id], productionWithOpenedNestedProductions));

      await dispatch(
        ProductionListActions.getAndSetProductionsByIds({
          groupId,
          fetchedProductionWorkflowsNumber: 0,
          productionIds: targetGroup.production_workflow_ids,
        }),
      );
    };
  }
}

export class ProductionListSelectors {
  static isSelectedProduction = (state: AppState, productionId: string) => {
    return state.production.productionList.selectedProductions.some(
      (selectedProduction) => selectedProduction.id === productionId,
    );
  };

  static isOpenedProduction = (state: AppState, productionId: string) => {
    return state.production.productionList.productionWithOpenedNestedProductions.some(
      (openedProductionId) => openedProductionId === productionId,
    );
  };

  static isOPenedProductionGroup = (state: AppState, productionGroupId: string) => {
    return state.production.productionList.openedProductionGroupIds.some((item) => item === productionGroupId);
  };

  static checkIfMassLaunchPossible = (state: AppState) => {
    const { productionList } = state.production;
    const { selectedProductions, launchingProductionIds } = productionList;

    const areAllSelectedProductionsNotInLaunching = !selectedProductions.some(
      (production) => launchingProductionIds.includes(production.id) || production.is_launch_in_progress,
    );
    const areAllMainProductionsAndInTodo = selectedProductions.every(
      (selectedProduction) => selectedProduction.status === ProductionStatusEnum.To_Do && !selectedProduction.main_root_id,
    );

    const noUnknownProducts = selectedProductions.every((production) => production.variant?.id !== undefined);

    return (
      !!selectedProductions.length &&
      areAllSelectedProductionsNotInLaunching &&
      areAllMainProductionsAndInTodo &&
      noUnknownProducts
    );
  };

  // static checkIfMassAssignManagersPossible = (state: AppState) => {
  //   const { productionList } = state.production;
  //   const { selectedProductions, launchingProductionIds } = productionList;

  //   const areAllSelectedProductionsNotInLaunching = !selectedProductions.some(
  //     (production) => launchingProductionIds.includes(production.id) || production.is_launch_in_progress,
  //   );

  //   return !!selectedProductions.length && areAllSelectedProductionsNotInLaunching;
  // };

  static checkIfProductionMasLaunching = (state: AppState, productionId: string) => {
    return state.production.productionList.launchingProductionIds.includes(productionId);
  };

  static isDeleteEnabled = (state: AppState) => {
    const { selectedProductions } = state.production.productionList;

    const canEveryComponentBeDeleted = selectedProductions.every(({ main_root_id, status, additionalComponents }) => {
      const flattenedAdditionalComponentsTree = getAllProductionsOnTheScreen(additionalComponents);
      const everyComponentFromAdditionalComponentsTreeInToDo = flattenedAdditionalComponentsTree.every(
        (production) => production.status === ProductionStatusEnum.To_Do,
      );

      return !main_root_id && status === ProductionStatusEnum.To_Do && everyComponentFromAdditionalComponentsTreeInToDo;
    });

    return selectedProductions.length > 0 && canEveryComponentBeDeleted;
  };
}

export const reducer = stateController.getReducer();
