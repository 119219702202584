import { PriorityEnum } from 'types/priority-enums';
import { ProductionStatusEnum } from 'types/status-enums';
import { Component } from 'pages/production/controllers/manage-components-modal.controller/manage-components-modal.controller';

export const SLOT = 'slot';
export const ADDITIONAL = 'additional';
export const DND_HOVER_COLOR = '#d1d9fa';
export const HIGHLIGHTED_SLOT = 'highlighted-slot-id';

export const EMPTY_SLOT: Component = {
  id: '',
  tags: [],
  notes: [],
  title: '',
  version: 0,
  issues: [],
  barcode: '',
  progress: 0,
  created_at: '',
  product_id: '',
  finished_at: '',
  deadline_at: '',
  product_type: '',
  product_name: '',
  main_root_id: '',
  ordersHistory: [],
  is_external: false,
  production_key: '',
  variantsHistory: [],
  product_meta_id: '',
  deadlineHistory: [],
  additionalZoneId: '',
  nested_workflows: [],
  additionalComponents: [],
  productionWorkflowItems: [],
  is_launch_in_progress: false,
  priority: PriorityEnum.Medium,
  externalOrderNumberHistory: [],
  parent_production_workflow_id: '',
  nestedProductionWorkflowItems: [],
  status: ProductionStatusEnum.To_Do,
  is_manual_assignmet_required: false,
  additional_component_has_issue: false,
  is_any_task_time_limit_exceeded: false,
  parentStatus: ProductionStatusEnum.To_Do,
  nested_production_component_has_issues: false,
  responsible: {
    id: '',
    last_name: '',
    first_name: '',
    avatar_image_url: '',
    user_position_slots: [],
  },
  configuration: {
    id: '',
    sku: '',
    name: '',
  },
  variant: {
    id: '',
    sku: '',
    name: '',
  },
  order: {
    client: {
      id: '',
      name: '',
      email: '',
      phone: '',
      company: '',
      external_client_id: '',
    },
    id: '',
    order_key: '',
    client_name: '',
    line_item_id: '',
    external_order_id: '',
    external_system_name: '',
    external_order_number: '',
    marketplace_order_number: '',
    priority: PriorityEnum.Medium,
  },
};
