import { notify } from 'notifications';
import { ColGroupDef, ValueSetterParams, ColDef, ColumnState } from 'ag-grid-community';
import { ManageTaskPriorityModalActions } from 'pages/production/controllers/manage-task-priority.controller';
import { DisplayRangeType } from 'pages/production/controllers/production-filters-controller/types';
import {
  agGridGroupedColumnDefinitions,
  TASKS_PER_PAGE,
  unassignedOption,
  valueOptionsSelectedOptions,
} from 'pages/tasks/constants';
import {
  getPinnedSide,
  getColumnWidth,
  filterValidFields,
  checkHiddenColumn,
  generateRequestBody,
  getFormattedFilters,
  getActiveFilters,
  sortSavedColumnsState,
  transformToAgGridColumnState,
} from 'pages/tasks/tasks.helpers';
import {
  ColumnOrderItemT,
  ColumnSizesT,
  ManageSelectOrDeselectAllTasksArgs,
  PerformerItemT,
  SelectedTaskT,
  TasksFiltersEnum,
  TasksFiltersT,
  TaskTableStateT,
} from 'pages/tasks/types/types';
import { AppState, GetStateFunction } from 'redux/store';
import { ProductionTaskService } from 'services/production-task.service';
import { ShowCompletedPeriodEnum } from 'services/production-workflow.model';
import { ProductionWorkflowService } from 'services/production-workflow.service';
import {
  TaskTableModel,
  TaskTableFiltersT,
  WebsocketResponseMessageForTaskTableT,
  FinishedAssignWebsocketResponseForTasksTableT,
} from 'services/task-table.model';
import { TaskTableService } from 'services/task-table.service';
import { AssignmentType } from 'services/workflow-task-template-responsibility.model';
import { StateController } from 'state-controller';
import { TaskTypeEnum, WebsocketEvent } from 'types/common-enums';
import { IdName, MetaT, PaginationData, SortOrderOption } from 'types/common-types';
import { UserService } from 'services/user.service';
import { PriorityEnum } from 'types/priority-enums';
import { ProductionStatusEnum, TaskStatusEnum, UserStatusEnum } from 'types/status-enums';
import { debounce } from 'utils/debounce';
import { TaskAssigmentService } from 'services/task-assigment.service';
import {
  AssignmentUser,
  GetTasksByFiltersArgs,
  HandleSlotIdsUpdateFromWebsocketArgs,
  HandleTasksIdsUpdateFromWebsocketArgs,
} from 'pages/tasks/types/function-types';
import { UserShortModel, User } from 'services/user.model';
import {
  collectAllTaskSlotIds,
  extractUpdatedTaskResponsibilitiesValues,
  getAllTasksOnTheScreen,
  getResponsibleId,
  mapAssignAt,
  prepareRequestBodyForMultipleBulkAssignUsers,
  prepareRequestBodyForSingleBulkAssignUsers,
  updateTasksFromWsResponse,
} from 'pages/tasks/helpers';
import { UNASSIGNED } from 'constants/unassigned';
import { PAGE_SIZE } from 'components/ui-new/dropdown-user-search-selector/dropdown-user-search-selector';
import { TasksLaunchingProgressActions } from 'pages/tasks/components/task-assign-users-progress-modal/task-assign-users-progress-modal.controller';
import axios from 'axios';
import { Dayjs } from 'dayjs';
import { DateRange } from '@mui/x-date-pickers-pro';

export type TasksState = {
  isLoading: boolean;
  editTaskId: string;
  isFetching: boolean;
  users: Array<User>;
  assignTaskIds: string[];
  assignSlotIds: string[];
  filters: TasksFiltersT;
  columnSizes: ColumnSizesT;
  selectedTasks: SelectedTaskT[];
  isInvalidSlotAssignment: boolean;
  isAccessDeniedModalOpen: boolean;
  activeFilters: TasksFiltersEnum[];
  atLeastOneFilterSelected: boolean;
  isAssignUsersModalOpened: boolean;
  is_assignment_in_progress: boolean;
  bulkAssignFailedTaskKeys: string[];
  isEditBasicRewardModalOpened: boolean;
  columnOrder: ColumnOrderItemT[];
  groupedColumnDefinition: ColGroupDef[];
  tasks: {
    meta: MetaT;
    data: TaskTableStateT[];
  };

  performer: {
    isLoading: Record<number, boolean>;
    options: Record<number, PerformerItemT[]>;
    value: Record<number, PerformerItemT | null>;
  };
  activeColumn: TasksFiltersEnum | '';
  paginationPerformers: Record<number, PaginationData>;
  selectedPerformersToMultiAssign: Record<number, string | null>;
  savedAndSortedColumnStateGrid: ColumnState[];
};

const tasksDefaultState: TasksState = {
  tasks: {
    data: [],
    meta: {
      currentPage: 0,
      lastPage: 0,
      next: 0,
      perPage: 0,
      prev: 0,
      total: 0,
    },
  },
  paginationPerformers: {},
  editTaskId: '',
  columnOrder: [],
  activeFilters: [],
  activeColumn: '',
  isLoading: false,
  isFetching: false,
  columnSizes: null,
  selectedTasks: [],
  groupedColumnDefinition: [],
  isAccessDeniedModalOpen: false,
  atLeastOneFilterSelected: false,
  isAssignUsersModalOpened: false,
  is_assignment_in_progress: false,
  isEditBasicRewardModalOpened: false,
  selectedPerformersToMultiAssign: {},
  bulkAssignFailedTaskKeys: [],
  users: [],
  performer: {
    isLoading: {},
    value: {},
    options: [],
  },
  assignTaskIds: [],
  assignSlotIds: [],
  isInvalidSlotAssignment: false,
  savedAndSortedColumnStateGrid: [],
  filters: {
    filters: {
      // Production
      [TasksFiltersEnum.ProductionDeadline]: { value: [null, null] },
      [TasksFiltersEnum.ProductionStatus]: {
        ...valueOptionsSelectedOptions,
        options: [
          { name: 'To Do', id: ProductionStatusEnum.To_Do },
          { name: 'Stopped', id: ProductionStatusEnum.Stopped },
          { name: 'In Progress', id: ProductionStatusEnum.In_Progress },
          { name: 'Done', id: ProductionStatusEnum.Done },
          { name: 'From stock', id: ProductionStatusEnum.From_Stock },
          { name: 'Canceled', id: ProductionStatusEnum.Canceled },
        ],
      },
      [TasksFiltersEnum.RootProductionDeadline]: { value: [null, null] },

      [TasksFiltersEnum.ShowCompleted]: {
        radioValue: ShowCompletedPeriodEnum.Some,
        value: { id: '1', name: '1 day' },
        options: [
          { id: '1', name: '1 day' },
          { id: '3', name: '3 days' },
          { id: '7', name: '7 days' },
          { id: '30', name: '30 days' },
          { id: '90', name: '90 days' },
        ],
      },

      // Task
      [TasksFiltersEnum.IsInQueue]: {
        value: '',
        options: [
          { name: 'Yes', id: 'true' },
          { name: 'No', id: 'false' },
        ],
        selectedOptions: [],
      },
      [TasksFiltersEnum.IsFailed]: {
        value: '',
        options: [
          { name: 'Yes', id: 'true' },
          { name: 'No', id: 'false' },
        ],
        selectedOptions: [],
      },
      [TasksFiltersEnum.FailedAt]: { value: [null, null] },
      [TasksFiltersEnum.TaskType]: {
        ...valueOptionsSelectedOptions,
        options: [
          { name: 'Workflow', id: TaskTypeEnum.Workflow },
          { name: 'Additional', id: TaskTypeEnum.Additional },
        ],
      },
      [TasksFiltersEnum.TaskStatus]: {
        ...valueOptionsSelectedOptions,
        options: [
          { name: 'Reopened', id: TaskStatusEnum.Reopened },
          { name: 'In Progress', id: TaskStatusEnum.In_Progress },
          { name: 'On hold', id: TaskStatusEnum.On_Hold },
          { name: 'To Do', id: TaskStatusEnum.To_Do },
          { name: 'Blocked', id: TaskStatusEnum.Blocked },
          { name: 'Done', id: TaskStatusEnum.Done },
          { name: 'Canceled', id: TaskStatusEnum.Canceled },
        ],
      },
      [TasksFiltersEnum.ReportingPeriod]: { value: [null, null] },
      [TasksFiltersEnum.TaskPriority]: {
        ...valueOptionsSelectedOptions,
        options: [
          { name: PriorityEnum.Highest, id: PriorityEnum.Highest },
          { name: PriorityEnum.High, id: PriorityEnum.High },
          { name: PriorityEnum.Medium, id: PriorityEnum.Medium },
          { name: PriorityEnum.Low, id: PriorityEnum.Low },
          { name: PriorityEnum.Lowest, id: PriorityEnum.Lowest },
        ],
      },
      [TasksFiltersEnum.ReasonForFailure]: valueOptionsSelectedOptions,

      // Assignment
      [TasksFiltersEnum.Assignee]: valueOptionsSelectedOptions,
      [TasksFiltersEnum.AssigneeType]: {
        ...valueOptionsSelectedOptions,
        options: [
          { name: 'Manual', id: AssignmentType.Manual },
          { name: 'Automatic', id: AssignmentType.Auto },
          { name: 'Self assignment', id: AssignmentType.Self_Assignment },
        ],
      },

      // Warnings
      [TasksFiltersEnum.AssigneeRequired]: {
        value: '',
        options: [
          { name: 'Yes', id: 'true' },
          { name: 'No', id: 'false' },
        ],
        selectedOptions: [],
      },
      [TasksFiltersEnum.TimeLimitExceeded]: {
        value: '',
        options: [
          { name: 'Yes', id: 'true' },
          { name: 'No', id: 'false' },
        ],
        selectedOptions: [],
      },

      // Time
      [TasksFiltersEnum.DueDate]: { value: [null, null] },
    },
    queries: {
      // Product
      [TasksFiltersEnum.ProductName]: '',
      [TasksFiltersEnum.ProductVersion]: 0,
      [TasksFiltersEnum.ProductVariant]: '',
      [TasksFiltersEnum.RootProductName]: '',
      [TasksFiltersEnum.RootProductVersion]: 0,
      [TasksFiltersEnum.RootProductVariant]: '',
      [TasksFiltersEnum.ProductConfiguration]: '',
      [TasksFiltersEnum.RootProductConfiguration]: '',

      // Production
      [TasksFiltersEnum.ProductionKey]: '',
      [TasksFiltersEnum.RootProductionKey]: '',

      // Order
      [TasksFiltersEnum.Client]: '',
      [TasksFiltersEnum.OrderKey]: '',
      [TasksFiltersEnum.PrimaryClient]: '',
      [TasksFiltersEnum.ExternalOrderNumber]: '',
      [TasksFiltersEnum.MarketPlaceOrderNumber]: '',

      // Task
      [TasksFiltersEnum.TaskKey]: '',
      [TasksFiltersEnum.TaskName]: '',
      [TasksFiltersEnum.ReasonForFailure]: '',

      // Assignment
      [TasksFiltersEnum.AssigneePosition]: '',
      [TasksFiltersEnum.AssigneeDepartment]: '',
    },
    pins: {
      left: [],
      right: [],
    },
    sort: {
      orderBy: [TasksFiltersEnum.RootProductionDeadline],
      orderOption: SortOrderOption.Ascending,
    },
  },
};

const stateController = new StateController<TasksState>('TASKS', tasksDefaultState);

// Abort controllers
let getTasksByFiltersAbortController: AbortController | null = null;

export class TasksActions {
  static initTasksPage() {
    return async (dispatch) => {
      dispatch(stateController.setState({ isLoading: true }));

      await dispatch(TasksActions.getTableFilters());
      await dispatch(TasksActions.checkIfAtLeastOneFilterSelected());
      await dispatch(TasksActions.getTasksByFilters({}));

      dispatch(stateController.setState({ isLoading: false }));
    };
  }

  public static clearTasksState() {
    return (dispatch) => dispatch(stateController.setState(tasksDefaultState));
  }

  static loadMoreTasks() {
    return (dispatch, getState: GetStateFunction) => {
      const { meta, data } = getState().tasks.tasks;

      if (data.length >= meta.total) return;

      dispatch(TasksActions.getTasksByFilters({ skip: meta.next, isFetching: true }));
    };
  }

  static getTableFilters() {
    return async (dispatch, getState: GetStateFunction) => {
      try {
        const filtersData: TaskTableFiltersT | undefined = await UserService.getTaskTableFilters();

        const modified = agGridGroupedColumnDefinitions.map((group) => ({
          ...group,
          children: group.children.map((column: ColDef) => ({
            ...column,
            pinned: getPinnedSide(column, filtersData.pins),
            width: getColumnWidth(column.field as TasksFiltersEnum, filtersData.sizes),
            hide: checkHiddenColumn(column.field as TasksFiltersEnum, filtersData.columns),
            sort: column.field === filtersData.sort?.orderBy?.[0] ? filtersData.sort?.orderOption : undefined,
            // Unfortunately this is how updates should happen. If you try to use onCellValueChanged
            // Ag-Grid will be mutating your original state and not via dispatch but like plane object mutations
            valueSetter: (params: ValueSetterParams<TaskTableModel>) =>
              dispatch(TasksActions.updateTask(params.data.id, { [params.colDef.field as string]: params.newValue })),
          })),
        }));

        const columnStateGrid = transformToAgGridColumnState(modified);

        const savedAndSortedColumnStateGrid = sortSavedColumnsState(columnStateGrid, filtersData.columns);

        const { filters } = getState().tasks.filters;

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            columnOrder: filtersData.columns || null,
            columnSizes: filtersData.sizes || null,
            groupedColumnDefinition: modified,
            savedAndSortedColumnStateGrid,
            activeFilters: getActiveFilters(filtersData),
            filters: {
              ...prev.filters,
              pins: {
                ...prev.filters.pins,
                ...filtersData.pins,
              },
              filters: {
                ...prev.filters.filters,
                ...getFormattedFilters(filtersData.filters, filters),
              },
              queries: {
                ...prev.filters.queries,
                ...filtersData.queries,
              },
              sort: {
                orderBy: filtersData.sort?.orderBy,
                orderOption: filtersData.sort?.orderOption,
              },
            },
          })),
        );
      } catch (err) {
        notify.error(err.message);
        throw err;
      }
    };
  }

  static getTasksByFilters({
    skip = 0,
    take = TASKS_PER_PAGE,
    isFetching = false,
    sorting = false,
    resetToDefault = false,
    filterChanged = false,
  }: GetTasksByFiltersArgs) {
    return async (dispatch, getState: GetStateFunction) => {
      const {
        tasks: { data },
        filters,
        columnOrder,
        columnSizes,
      } = getState().tasks;

      if (getTasksByFiltersAbortController) {
        getTasksByFiltersAbortController.abort();
      }

      getTasksByFiltersAbortController = new AbortController();
      const { signal } = getTasksByFiltersAbortController;

      try {
        dispatch(stateController.setState({ isLoading: !isFetching, isFetching }));

        const columns = columnOrder?.length ? filterValidFields(columnOrder) : null;
        const body = generateRequestBody(filters, columns, columnSizes);

        // For resetting state of columns
        const defaultBody = generateRequestBody(tasksDefaultState.filters, null, null);

        const response = await TaskTableService.getAllTask(resetToDefault ? defaultBody : body, { skip, take }, signal);

        const mappedData = response.data.map((task: TaskTableModel) => ({
          ...task,
          assigned_at:
            (task.assigned_at && task.assignee && mapAssignAt({ assignee: task.assignee, assigned_ats: task.assigned_at })) ||
            null,
        }));

        dispatch(
          stateController.setState({
            tasks: {
              ...response,
              data: filterChanged || sorting || resetToDefault ? mappedData : [...data, ...mappedData],
            },
          }),
        );
      } catch (error) {
        if (axios.isCancel(error)) return;

        notify.error(error.message);
      } finally {
        dispatch(stateController.setState({ isLoading: false, isFetching: false }));
        getTasksByFiltersAbortController = null;
      }
    };
  }

  static resetColumnStates() {
    return async (dispatch) => {
      dispatch(
        stateController.setState({
          selectedTasks: [],
          isAssignUsersModalOpened: false,
          selectedPerformersToMultiAssign: [],
          filters: tasksDefaultState.filters,
        }),
      );

      const body = generateRequestBody(tasksDefaultState.filters, null, null);

      await UserService.setTableFilters(body);
      await dispatch(TasksActions.getTableFilters());
      await dispatch(TasksActions.getTasksByFilters({ resetToDefault: true }));
    };
  }

  static onColumnMoved(newColumnOrder: ColumnOrderItemT[]) {
    return async (dispatch, getState: GetStateFunction) => {
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            columnOrder: newColumnOrder,
          })),
        );

        const { filters, columnSizes } = getState().tasks;
        const fields = filterValidFields(newColumnOrder);
        const body = generateRequestBody(filters, fields, columnSizes);

        await UserService.setTableFilters(body);
      } catch (error) {
        notify.error(error.message);
      }
    };
  }

  static onColumnChecked(newColumnOrder: ColumnOrderItemT[], isColumnVisible: boolean) {
    return async (dispatch, getState: GetStateFunction) => {
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            columnOrder: newColumnOrder,
          })),
        );

        const { filters, columnSizes } = getState().tasks;
        const fields = filterValidFields(newColumnOrder);
        const body = generateRequestBody(filters, fields, columnSizes);

        await UserService.setTableFilters(body);

        if (isColumnVisible) {
          await dispatch(
            TasksActions.getTasksByFilters({ skip: 0, take: TASKS_PER_PAGE, isFetching: true, filterChanged: true }),
          );
        }
      } catch (error) {
        notify.error(error.message);
      }
    };
  }

  static onColumnPinned(side: 'left' | 'right' | null, columnName: TasksFiltersEnum) {
    return async (dispatch, getState: GetStateFunction) => {
      try {
        const { left, right } = getState().tasks.filters.pins;
        let pinsLeft = [...left];
        let pinsRight = [...right];

        if (side === 'left') {
          pinsLeft = [...pinsLeft, columnName];
        } else if (side === 'right') {
          pinsRight = [...pinsRight, columnName];
        } else {
          pinsLeft = pinsLeft.filter((i) => i !== columnName);
          pinsRight = pinsRight.filter((i) => i !== columnName);
        }

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            filters: {
              ...prev.filters,
              pins: {
                left: pinsLeft,
                right: pinsRight,
              },
            },
          })),
        );

        const { filters, columnOrder, columnSizes } = getState().tasks;
        const fields = columnOrder ? filterValidFields(columnOrder) : null;
        const body = generateRequestBody(filters, fields, columnSizes);

        await UserService.setTableFilters(body);
      } catch (error) {
        notify.error(error.message);
      }
    };
  }

  static onSortChanged(columnName: TasksFiltersEnum | null, sortOrder: SortOrderOption | null) {
    return async (dispatch, getState: GetStateFunction) => {
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            filters: {
              ...prev.filters,
              sort: {
                orderBy: [columnName],
                orderOption: sortOrder,
              },
            },
          })),
        );

        const { filters, columnOrder, columnSizes, tasks } = getState().tasks;
        const fields = columnOrder ? filterValidFields(columnOrder) : null;
        const body = generateRequestBody(filters, fields, columnSizes);

        await UserService.setTableFilters(body);

        dispatch(TasksActions.getTasksByFilters({ isFetching: true, sorting: true, take: tasks.data.length }));
      } catch (err) {
        notify.error(err.message);
        throw err;
      }
    };
  }

  static onSizeChanged(columnName: TasksFiltersEnum, newSize: number) {
    return async (dispatch, getState: GetStateFunction) => {
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            columnSizes: {
              ...prev.columnSizes,
              [columnName]: newSize,
            },
          })),
        );

        const { filters, columnOrder, columnSizes } = getState().tasks;
        const fields = columnOrder ? filterValidFields(columnOrder) : null;
        const body = generateRequestBody(filters, fields, columnSizes);

        await UserService.setTableFilters(body);
      } catch (err) {
        notify.error(err.message);
        throw err;
      }
    };
  }

  public static changeTaskDisplayRange(value: Partial<DisplayRangeType>) {
    return async (dispatch, getState: GetStateFunction) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          filters: {
            ...prev.filters,
            filters: {
              ...prev.filters.filters,
              [TasksFiltersEnum.ShowCompleted]: {
                ...prev.filters.filters[TasksFiltersEnum.ShowCompleted],
                ...value,
              },
            },
          },
        })),
      );
      const { filters, columnOrder, columnSizes } = getState().tasks;
      const fields = columnOrder ? filterValidFields(columnOrder) : null;
      const body = generateRequestBody(filters, fields, columnSizes);

      await UserService.setTableFilters(body);

      dispatch(TasksActions.getTasksByFilters({ isFetching: true, filterChanged: true }));
    };
  }

  static setFilters(values: Partial<TasksFiltersT>, extendExistingValue?: boolean) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prevState) => ({
          ...prevState,
          filters: (Object.keys(values) as [keyof Partial<TasksFiltersT>]).reduce((acc, item) => {
            const currentValue = values[item];
            const previousFilter = prevState.filters[item];

            // The "undefined" comparison is needed to allow empty string in search filter
            if (currentValue === undefined) {
              return { ...acc, [item]: previousFilter };
            }

            if (
              extendExistingValue ||
              typeof currentValue === 'boolean' ||
              (Array.isArray(currentValue) && typeof currentValue[0] === 'object')
            ) {
              return { ...acc, [item]: { ...previousFilter, value: currentValue } };
            }

            return { ...acc, [item]: { value: currentValue } };
          }, prevState.filters),
        })),
      );
    };
  }

  static updateTask(taskId: string, value: Partial<TaskTableStateT>) {
    return async (dispatch) => {
      try {
        const data = await ProductionTaskService.updateTask(taskId, value);
        const { positions, departments, assignAt } = extractUpdatedTaskResponsibilitiesValues(data);

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            tasks: {
              ...prev.tasks,
              data: prev.tasks.data.map((task) => {
                if (task.id === taskId) {
                  const updatedAssignee = value.assignee
                    ? task.assignee.map((currentAssignee) => {
                        if (currentAssignee.slot_id === value.assignee?.[0].slot_id) {
                          return value.assignee?.[0];
                        }

                        return currentAssignee;
                      })
                    : task.assignee;

                  const updatedSlots = value.task_slots
                    ? task.task_slots.map((currentSlots) => {
                        if (currentSlots.id === value.task_slots?.[0].id) {
                          return value.task_slots?.[0];
                        }
                        return currentSlots;
                      })
                    : task.task_slots;
                  return {
                    ...task,
                    ...value,
                    assignee: updatedAssignee,
                    task_slots: updatedSlots,
                    assignee_position: positions,
                    assignee_department: departments,
                    assigned_at: assignAt,
                  };
                }

                return task;
              }),
            },
          })),
        );
      } catch (error) {
        notify.error(error.message);
      }
    };
  }

  static updateMultipleTaskPriority(taskIds: string[], newPriority: PriorityEnum) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          tasks: {
            ...prev.tasks,
            data: prev.tasks.data.map((task) => {
              if (taskIds.includes(task.id)) {
                return {
                  ...task,
                  priority: newPriority,
                };
              }

              return task;
            }),
          },
        })),
      );
    };
  }

  static assignUserToTask(taskId: string, user: UserShortModel, slotId: string, responsibilityId: string) {
    return async (dispatch) => {
      try {
        await TaskAssigmentService.assignUser(user.id, slotId);

        const assignee = {
          slot_id: slotId,
          task_responsibility_id: responsibilityId,
          taskAssignment: {
            user: {
              id: user.id,
              first_name: user.first_name,
              last_name: user.last_name,
              avatar_image_url: user.avatar_image_url,
            },
          },
        };

        const task_slots = {
          id: slotId,
          task_assignment_id: user.id,
          task_responsibility_id: responsibilityId,
        };

        dispatch(
          TasksActions.updateTask(taskId, {
            assignee: [assignee],
            task_slots: [task_slots],
          }),
        );
      } catch (error) {
        notify.error(error.message);
      }
    };
  }

  static unassignUserFromTask(taskId: string, user: AssignmentUser, slotId: string) {
    return async (dispatch) => {
      try {
        await TaskAssigmentService.unassignUser(slotId);

        const task_slots = {
          id: slotId,
          task_responsibility_id: user.task_responsibility_id,
        };

        dispatch(
          TasksActions.updateTask(taskId, {
            assignee: [user],
            task_slots: [task_slots],
          }),
        );
      } catch (error) {
        notify.error(error.message);
      }
    };
  }

  static copyTaskLink(taskId: string) {
    return async () => {
      const { origin } = window.location;
      const link = `${origin}/task/${taskId}`;

      await navigator.clipboard.writeText(link);

      notify.success('The link is copied');
    };
  }

  static onFilterChange(
    fieldName: TasksFiltersEnum,
    value: string | boolean | UserShortModel | null | DateRange<Dayjs>,
    id?: string,
  ) {
    return async (dispatch, getState: GetStateFunction) => {
      const { filters, columnOrder, columnSizes } = getState().tasks;

      const isFilterField = fieldName in filters.filters;
      const isQueryField = fieldName in filters.queries;

      const updatedFilters = { ...filters };

      if (isFilterField) {
        const currentSelectedOptions = filters.filters[fieldName].selectedOptions || [];

        const updatedSelectedOptions = currentSelectedOptions.includes(id)
          ? currentSelectedOptions.filter((option) => option !== id)
          : [...currentSelectedOptions, id];

        updatedFilters.filters = {
          ...filters.filters,
          [fieldName]: {
            ...filters.filters[fieldName],
            value,
            selectedOptions: updatedSelectedOptions,
          },
        };
      }

      if (isQueryField) {
        updatedFilters.queries = {
          ...filters.queries,
          [fieldName]: value,
        };
      }

      // for 'reason_for_failure'
      if (isFilterField && isQueryField) {
        const cleanedSelectedOptions = updatedFilters.filters[fieldName].selectedOptions?.filter((option) => option);

        updatedFilters.filters[fieldName] = {
          ...updatedFilters.filters[fieldName],
          value,
          selectedOptions: cleanedSelectedOptions,
        };
      }

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          filters: updatedFilters,
          activeColumn: fieldName,
          activeFilters: getActiveFilters(generateRequestBody(updatedFilters, columnOrder, columnSizes)),
        })),
      );

      debounce(async () => {
        const fields = columnOrder ? filterValidFields(columnOrder) : null;
        const body = generateRequestBody(updatedFilters, fields, columnSizes);

        await UserService.setTableFilters(body);

        await dispatch(TasksActions.getTasksByFilters({ isFetching: true, filterChanged: true }));
        await dispatch(TasksActions.checkIfAtLeastOneFilterSelected());
      }, 500);
    };
  }

  private static hasSelectedValues(filterValue: typeof valueOptionsSelectedOptions) {
    if (Array.isArray(filterValue.value)) {
      return filterValue.value.some((value) => value !== null);
    }

    return filterValue.selectedOptions && filterValue.selectedOptions.length > 0;
  }

  static checkIfAtLeastOneFilterSelected() {
    return (dispatch, getState: GetStateFunction) => {
      const { filters, queries } = getState().tasks.filters;

      const hasOptionsFilter = Object.entries(filters).some(([, filterValue]) =>
        TasksActions.hasSelectedValues(filterValue as typeof valueOptionsSelectedOptions),
      );

      const hasQueryFilter = Object.values(queries).some((queryValue) => queryValue !== '' && queryValue !== 0);

      const atLeastOneFilterSelected = hasOptionsFilter || hasQueryFilter;

      dispatch(stateController.setState({ atLeastOneFilterSelected }));
    };
  }

  static setFilterOptions(fieldName: TasksFiltersEnum, options: IdName[]) {
    return (dispatch, getState: GetStateFunction) => {
      const { filters } = getState().tasks;

      const currentFilter = filters?.filters[fieldName] || {};

      dispatch(
        stateController.setState({
          filters: {
            ...filters,
            filters: {
              ...filters.filters,
              [fieldName]: {
                ...currentFilter,
                options,
              },
            },
          },
        }),
      );
    };
  }

  static openTaskInNewTab(taskId: string) {
    return () => {
      const { origin } = window.location;
      const link = `${origin}/task/${taskId}`;

      window.open(link, '_blank');
    };
  }

  static openRootOrMainProductionInNewTab(productionId: string | null) {
    return () => {
      if (!productionId) return;

      const { origin } = window.location;
      const link = `${origin}/production-workflow/${productionId}`;

      window.open(link, '_blank');
    };
  }

  static openManageTaskPriorityModal(productionId: string, taskId: string) {
    return async (dispatch) => {
      try {
        const production = await ProductionWorkflowService.getProductionWorkflowInfo(productionId);

        if (production) {
          dispatch(ManageTaskPriorityModalActions.openModal({ production, taskId }));
        }
      } catch (error) {
        notify.error(error.message);
      }
    };
  }

  // ====== Edit basic reward modal =====================================================================================================

  public static openEditBasicRewardModal(taskId: string) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          isEditBasicRewardModalOpened: true,
          editTaskId: taskId,
        })),
      );
    };
  }

  public static closeEditBasicRewardModal() {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          isEditBasicRewardModalOpened: false,
          editTaskId: '',
        })),
      );
    };
  }

  // ====== Bulk actions assign user ====================================================================================================

  public static initPerformersForSingleTaskSlotAssignment() {
    return async (dispatch) => {
      try {
        const users = (
          await UserService.getAllUsers({
            skip: 0,
            take: PAGE_SIZE,
            status: [UserStatusEnum.Active],
          })
        ).data;
        dispatch(stateController.setState({ users }));
      } catch (error) {
        notify.error(error.message);
      }
    };
  }

  public static bulkAssignMultipleOrSingleUserToTasks(responsibleId?: string) {
    return async (dispatch, getState: () => AppState) => {
      const { selectedPerformersToMultiAssign, selectedTasks } = getState().tasks;

      const performerId = getResponsibleId(responsibleId);

      const assignToManyTasksBody = responsibleId
        ? prepareRequestBodyForSingleBulkAssignUsers(performerId, selectedTasks)
        : prepareRequestBodyForMultipleBulkAssignUsers(selectedPerformersToMultiAssign, selectedTasks);

      if (assignToManyTasksBody.length === 0) return;

      const allTaskSlotIds = collectAllTaskSlotIds(assignToManyTasksBody);

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          assignSlotIds: allTaskSlotIds,
        })),
      );

      try {
        await TaskTableService.bulkAssignUsersToTasks(assignToManyTasksBody);
        if (selectedTasks.length) {
          dispatch(TasksLaunchingProgressActions.showModalAndSetAssignUsersTasksCount(selectedTasks.length));
        }
      } catch (error) {
        notify.error(error.message);
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            assignSlotIds: [],
          })),
        );
      } finally {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            selectedTasks: [],
            didFiltersChange: false,
            selectedPerformersToMultiAssign: {},
            performer: {
              isLoading: {},
              value: {},
              options: [],
            },
          })),
        );
      }
    };
  }

  public static manageSelectOrDeselectAllTasks = ({ resetAll }: ManageSelectOrDeselectAllTasksArgs) => {
    return (dispatch, getState: () => AppState) => {
      const { data } = getState().tasks.tasks;
      const { selectedTasks } = getState().tasks;
      if (resetAll || selectedTasks.length) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            selectedTasks: [],
            assignTaskIds: [],
          })),
        );
        return;
      }

      const tasks = getAllTasksOnTheScreen(data);

      const updateAssignTaskIds = tasks.map((task) => task.id);

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          selectedTasks: tasks,
          assignTaskIds: updateAssignTaskIds,
        })),
      );
    };
  };

  public static handleSelectDeselectTasks = (task: TaskTableStateT) => {
    return (dispatch, getState: () => AppState) => {
      const { selectedTasks, tasks, assignTaskIds } = getState().tasks;
      const isSelected = selectedTasks.some((item) => item.id === task.id);
      const actualTask = tasks.data.find((t) => t.id === task.id);

      const updatedSelectedTasks = isSelected
        ? selectedTasks.filter((item) => item.id !== task.id)
        : [
            ...selectedTasks,
            {
              id: task.id,
              slots:
                actualTask?.task_slots.map((task_slot) => ({
                  slot_id: task_slot.id,
                  task_responsibility_id: task_slot.task_responsibility_id,
                })) || [],
              departmentIds: task.department_ids,
            },
          ];

      const updatedAssignTaskIds = isSelected ? assignTaskIds.filter((id) => id !== task.id) : [...assignTaskIds, task.id];

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          selectedTasks: updatedSelectedTasks,
          assignTaskIds: updatedAssignTaskIds,
        })),
      );
    };
  };

  static openAssignUsersModal(value: boolean) {
    return (dispatch) => {
      dispatch(stateController.setState({ isAssignUsersModalOpened: value }));
    };
  }

  static onChangeAssignmentSlotModalValue(slotIndex: number, value: PerformerItemT) {
    return (dispatch, getState: () => AppState) => {
      const { selectedPerformersToMultiAssign } = getState().tasks;

      const transformedId = value.id === UNASSIGNED ? null : value.id;

      const updatedSlots = {
        ...selectedPerformersToMultiAssign,
        [slotIndex]: transformedId,
      };

      const assignedIds = Object.values(updatedSlots).filter((id) => Boolean(id));

      const hasDuplicates = new Set(assignedIds).size !== assignedIds.length;

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          selectedPerformersToMultiAssign: updatedSlots,
          performer: {
            ...prev.performer,
            value: {
              ...prev.performer.value,
              [slotIndex]: value,
            },
          },
          isInvalidSlotAssignment: hasDuplicates,
        })),
      );
    };
  }

  public static initPerformersForMultiTaskSlotAssignment(slotIndex: number) {
    return async (dispatch, getState: GetStateFunction) => {
      const { options } = getState().tasks.performer;
      if (options[slotIndex] && Object.keys(options[slotIndex]).length) return;
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            performer: {
              ...prev.performer,
              isLoading: {
                ...prev.performer.isLoading,
                [slotIndex]: true,
              },
            },
          })),
        );

        const { data, meta } = await UserService.getAllUsers({
          skip: 0,
          take: PAGE_SIZE,
          status: [UserStatusEnum.Active],
        });

        const newOptions = data.map((performer) => ({
          id: performer.id,
          name: `${performer.first_name} ${performer.last_name}`.trim(),
          avatar: performer.avatar_image_url,
          lastName: performer.last_name,
          firstName: performer.first_name,
        }));

        const newOptionsWitUnassignOption = [unassignedOption, ...newOptions];

        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            paginationPerformers: {
              ...prev.paginationPerformers,
              [slotIndex]: {
                total: meta.total,
                currentPage: meta.currentPage,
                perPage: meta.perPage,
                lastPage: meta.lastPage,
                next: meta.next,
              },
            },
            performer: {
              ...prev.performer,
              options: {
                ...prev.performer.options,
                [slotIndex]: newOptionsWitUnassignOption,
              },
              isLoading: {
                ...prev.performer.isLoading,
                [slotIndex]: false,
              },
            },
          })),
        );
      } catch (error) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            performer: {
              ...prev.performer,
              isLoading: {
                ...prev.performer.isLoading,
                [slotIndex]: false,
              },
            },
          })),
        );
        notify.error(error.message);
      }
    };
  }

  public static loadMoreOrSearchPerformersForMultiTaskSlotAssignment(
    slotIndex: number,
    value: string = '',
    isScroll: boolean = false,
  ) {
    return async (dispatch, getState: GetStateFunction) => {
      if (!isScroll) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            paginationPerformers: {
              ...prev.paginationPerformers,
              [slotIndex]: {
                ...prev.paginationPerformers[slotIndex],
                next: 0,
              },
            },
          })),
        );
      }
      const { paginationPerformers } = getState().tasks;
      if (isScroll && paginationPerformers[slotIndex].currentPage >= paginationPerformers[slotIndex].lastPage) return;
      try {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            performer: {
              ...prev.performer,
              isLoading: {
                ...prev.performer.isLoading,
                [slotIndex]: true,
              },
            },
          })),
        );
        debounce(async () => {
          const { data, meta } = await UserService.getAllUsers({
            search: value.trim(),
            skip: paginationPerformers[slotIndex].next,
            take: paginationPerformers[slotIndex].perPage,
            status: [UserStatusEnum.Active],
          });

          const newOptions = data.map((performer) => ({
            id: performer.id,
            name: `${performer.first_name} ${performer.last_name}`.trim(),
            avatar: performer.avatar_image_url,
            lastName: performer.last_name,
            firstName: performer.first_name,
          }));

          dispatch(
            stateController.setState((prev) => {
              const currentOptions: PerformerItemT[] = prev.performer.options[slotIndex] || [];
              const unassignedUserOptionExists = currentOptions.some((option) => option.id === unassignedOption.id);

              // eslint-disable-next-line no-nested-ternary
              const updatedSlotOptions = isScroll
                ? [...currentOptions, ...newOptions]
                : unassignedUserOptionExists
                ? newOptions
                : [unassignedOption, ...newOptions];

              return {
                ...prev,
                performer: {
                  ...prev.performer,
                  options: {
                    ...prev.performer.options,
                    [slotIndex]: updatedSlotOptions,
                  },
                  isLoading: {
                    ...prev.performer.isLoading,
                    [slotIndex]: false,
                  },
                },
                paginationPerformers: {
                  ...prev.paginationPerformers,
                  [slotIndex]: {
                    total: meta.total,
                    currentPage: meta.currentPage,
                    perPage: meta.perPage,
                    lastPage: meta.lastPage,
                    next: meta.next,
                  },
                },
              };
            }),
          );
        }, 500);
      } catch (error) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            performer: {
              ...prev.performer,
              isLoading: {
                ...prev.performer.isLoading,
                [slotIndex]: false,
              },
            },
          })),
        );
        notify.error(error.message);
      }
    };
  }

  // ====== WS handlers ==================================================================================================================

  static handleTasksIdsUpdateFromWebsocket = ({ value, removeLaunchedTaskIds }: HandleTasksIdsUpdateFromWebsocketArgs) => {
    return (dispatch) => {
      if (removeLaunchedTaskIds) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            assignTaskIds: prev.assignTaskIds.filter((launchingId) => !value.includes(launchingId)),
          })),
        );
      }
    };
  };

  static handleSlotIdsUpdateFromWebsocket = ({ value, removeLaunchedSlotIds }: HandleSlotIdsUpdateFromWebsocketArgs) => {
    return (dispatch) => {
      if (removeLaunchedSlotIds) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            assignSlotIds: prev.assignSlotIds.filter((launchingId) => !value.includes(launchingId)),
          })),
        );
      }
    };
  };

  static replaceUserInTaskSlotFromWebsocket(
    taskId: string,
    user: FinishedAssignWebsocketResponseForTasksTableT['user'],
    slotId: string,
    isUnassignFinishedEvent: boolean,
  ) {
    return (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          tasks: {
            ...prev.tasks,
            data: updateTasksFromWsResponse({
              id: taskId,
              slotId,
              user,
              tasks: prev.tasks,
              isUnassignFinishedEvent,
            }).data,
          },
        })),
      );
    };
  }

  static handleWebsocketResponse = (message: WebsocketResponseMessageForTaskTableT, event: WebsocketEvent) => {
    return (dispatch) => {
      const { task_id, slot_id, task_key, user } = message;

      const isAssignFinishedEvent = event === WebsocketEvent.TaskAssignmentFinished;
      const isUnassignFinishedEvent = event === WebsocketEvent.TaskUnAssignmentFinished;
      const isAssignFailedEvent = event === WebsocketEvent.TaskAssignmentFailed;
      const isUnassignFailedEvent = event === WebsocketEvent.TaskUnAssignmentFailed;

      if (isAssignFailedEvent || isUnassignFailedEvent) {
        dispatch(
          stateController.setState((prev) => ({
            ...prev,
            bulkAssignFailedTaskKeys: [...prev.bulkAssignFailedTaskKeys, task_key],
          })),
        );
      }
      if (isAssignFinishedEvent || isUnassignFinishedEvent) {
        setTimeout(
          () => dispatch(TasksActions.replaceUserInTaskSlotFromWebsocket(task_id, user, slot_id, isUnassignFinishedEvent)),
          200,
        );
      }

      dispatch(
        TasksActions.handleTasksIdsUpdateFromWebsocket({
          value: [task_id],
          removeLaunchedTaskIds: true,
        }),
      );

      dispatch(
        TasksActions.handleSlotIdsUpdateFromWebsocket({
          value: [slot_id],
          removeLaunchedSlotIds: true,
        }),
      );

      dispatch(TasksActions.handleNotificationAndProgressBulkAssignment());
    };
  };

  static handleNotificationAndProgressBulkAssignment = () => {
    return (dispatch, getState: () => AppState) => {
      const { assignTaskIds, assignSlotIds, bulkAssignFailedTaskKeys } = getState().tasks;

      if (assignSlotIds.length === 0) {
        if (bulkAssignFailedTaskKeys.length === 0) {
          notify.success('Successfully updated');
          dispatch(TasksLaunchingProgressActions.hideModal());
        } else {
          notify.error(
            <div>
              <p style={{ whiteSpace: 'nowrap' }}>Users weren&apos;t assigned to the following tasks:</p>
              <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                {bulkAssignFailedTaskKeys.map((el) => (
                  <li key={el}>
                    <strong>{el}</strong>
                  </li>
                ))}
              </ul>
            </div>,
          );
          dispatch(TasksLaunchingProgressActions.hideModal());
          dispatch(
            stateController.setState((prev) => ({
              ...prev,
              bulkAssignFailedTaskKeys: [],
            })),
          );
        }
      } else {
        dispatch(TasksLaunchingProgressActions.setTasksAssignUsersCount(assignTaskIds.length));
      }
    };
  };
}

export class TasksSelectors {
  public static isSelectedTask = (state: AppState, taskId: string): boolean => {
    return state.tasks.selectedTasks.some((selectedTask) => selectedTask.id === taskId);
  };

  public static canMassAssignUser = (state: AppState): boolean => {
    const { selectedTasks } = state.tasks;
    return selectedTasks.length > 0 && selectedTasks.every((task) => task.slots.length === selectedTasks[0].slots.length);
  };

  public static canSingleOrMultiAssignUser = (state: AppState): boolean => {
    const { selectedTasks } = state.tasks;
    return selectedTasks.some((task) => task.slots.length >= 2);
  };
}

export const tasksReducer = stateController.getReducer();
