import { StateController } from 'state-controller';
import { ProductionWorkflow, ProductionWorkflowInfo, ProductionWorkflowTagModel } from 'services/production-workflow.model';
import { ProductionWorkflowService } from 'services/production-workflow.service';
import { notify } from 'notifications';
import { debounce } from 'utils/debounce';
import { GetStateFunction } from 'redux/store';
import { Actions as ProductionWorkflowActions } from 'pages/production-workflow/controllers/production-workflow.controller';
import { ProductionListActions } from 'pages/production/controllers/production-list-controller/production-list.controller';
import { Paths } from 'routes/paths';

export type ManageProductionTagsArgs = {
  production: ProductionWorkflow | ProductionWorkflowInfo;
};

export type ManageProductionTagsState = {
  isOpen: boolean;
  searchValue: string;
  isProcessing: boolean;
  productionInfo: {
    id: string;
    productName: string;
    tags: ProductionWorkflowTagModel[];
  };
  newTags: ProductionWorkflowTagModel[];
  allTags: ProductionWorkflowTagModel[];
};

const defaultState: ManageProductionTagsState = {
  isOpen: false,
  searchValue: '',
  isProcessing: false,
  productionInfo: {
    id: '',
    tags: [],
    productName: '',
  },
  allTags: [],
  newTags: [],
};

const stateController = new StateController<ManageProductionTagsState>('MANAGE_PRODUCTION_TAGS_MODAL', defaultState);

export class ManageProductionTagsModalActions {
  public static openModal({ production }: ManageProductionTagsArgs) {
    return async (dispatch) => {
      try {
        const allTags = await ProductionWorkflowService.getAllProductionTags();

        const productionTagNames = new Set(production.tags.map((tag) => tag.name));

        const sortedTags = allTags.sort((a, b) => {
          const isAInProduction = productionTagNames.has(a.name);
          const isBInProduction = productionTagNames.has(b.name);

          if (isAInProduction && !isBInProduction) {
            return -1;
          }

          if (!isAInProduction && isBInProduction) {
            return 1;
          }

          return a.name.localeCompare(b.name);
        });

        dispatch(
          stateController.setState({
            isOpen: true,
            productionInfo: {
              productName: production.product_name,
              id: production.id,
              tags: production.tags,
            },
            allTags: sortedTags,
          }),
        );
      } catch (error) {
        notify.error(error.message);
      }
    };
  }

  public static closeModal() {
    return async (dispatch) => {
      dispatch(stateController.setState({ isOpen: false }));

      setTimeout(() => {
        dispatch(stateController.setState({ newTags: [] }));
      }, 100);
    };
  }

  public static handleSearch(value: string) {
    return async (dispatch) => {
      dispatch(stateController.setState({ searchValue: value }));
    };
  }

  public static handleCheckedChange(productionId: string, tagId: string, route: Paths) {
    return (dispatch, getState: GetStateFunction) => {
      const { productionInfo, allTags, newTags } = getState().production.manageProductionTagsModal;

      const isTagAttached = productionInfo.tags.some((tag) => tag.id === tagId);
      const targetTag = [...newTags, ...allTags].find((tag) => tag.id === tagId);

      dispatch(
        stateController.setState((prev) => {
          let updatedTags: ProductionWorkflowTagModel[];

          if (isTagAttached) {
            updatedTags = prev.productionInfo.tags.filter((tag) => tag.id !== tagId);
          } else if (targetTag) {
            updatedTags = [targetTag, ...prev.productionInfo.tags];
          } else {
            updatedTags = prev.productionInfo.tags;
          }

          return {
            ...prev,
            isProcessing: true,
            productionInfo: {
              ...prev.productionInfo,
              tags: updatedTags,
            },
          };
        }),
      );

      debounce(async () => {
        try {
          if (isTagAttached) {
            await ProductionWorkflowService.detachTagFromProduction(productionId, tagId);
          } else {
            await ProductionWorkflowService.attachTagToProduction(productionId, tagId);
          }

          if (targetTag) {
            if (route === Paths.Production) {
              dispatch(ProductionListActions.silentAttachOrDetachTags(productionId, targetTag));
            } else {
              dispatch(ProductionWorkflowActions.silentUpdateTags());
            }
          }
        } catch (error) {
          dispatch(
            stateController.setState((prev) => ({
              ...prev,
              productionInfo: {
                ...prev.productionInfo,
                tags: productionInfo.tags,
              },
            })),
          );
        } finally {
          dispatch(stateController.setState({ isProcessing: false }));
        }
      }, 200);
    };
  }

  public static addTag(tag: ProductionWorkflowTagModel) {
    return async (dispatch) => {
      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          newTags: [tag, ...prev.newTags].sort((a, b) => a.name.localeCompare(b.name)),
        })),
      );
    };
  }

  public static updateTag(tag: ProductionWorkflowTagModel, route: Paths) {
    return async (dispatch) => {
      const updateTagInArray = (array: ProductionWorkflowTagModel[]) => {
        return array.map((item) => (item.id === tag.id ? { ...item, ...tag } : item));
      };

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          newTags: updateTagInArray(prev.newTags),
          productionInfo: {
            ...prev.productionInfo,
            tags: updateTagInArray(prev.productionInfo.tags),
          },
          allTags: updateTagInArray(prev.allTags),
        })),
      );

      if (route === Paths.Production) {
        dispatch(ProductionListActions.silentUpdateTags(tag));
      } else {
        dispatch(ProductionWorkflowActions.silentUpdateTags());
      }
    };
  }

  public static deleteTag(tag: ProductionWorkflowTagModel, route: Paths) {
    return async (dispatch) => {
      const filterTags = (tags: ProductionWorkflowTagModel[]) => tags.filter(({ id }) => id !== tag.id);

      dispatch(
        stateController.setState((prev) => ({
          ...prev,
          productionInfo: {
            ...prev.productionInfo,
            tags: filterTags(prev.productionInfo.tags),
          },
          newTags: filterTags(prev.newTags),
          allTags: filterTags(prev.allTags),
        })),
      );

      if (route === Paths.Production) {
        dispatch(ProductionListActions.silentDeleteTags(tag));
      } else {
        dispatch(ProductionWorkflowActions.silentUpdateTags());
      }
    };
  }
}

export const manageProductionTagsModalReducer = stateController.getReducer();
